<script setup>
import SignedImage from '@/components/SignedImage.vue';
import { headshotUrlsFor, fetchHeadshotUrls } from '@/utilities/headshots';
import { computed } from 'vue';
import StubImage from '@/components/StubImage.vue';
import FullHeadshotDialog from '@/dialogs/FullHeadshotDialog.vue';
import { openDialog } from '@/dialogs';

const props = defineProps({
  actorHeadshotId: {
    type: String,
    default: null,
  },
  stubName: {
    type: String,
    default: null,
  },
  height: {
    type: Number,
    default: 96,
  },
  width: {
    type: Number,
    default: 96,
  },
  allowZoom: Boolean,
});

const showImage = computed(() => {
  if (!props.stubName) return true;
  return !!props.actorHeadshotId;
});

const hasImage = computed(() => {
  return !!props.actorHeadshotId;
});

function doZoom() {
  openDialog(FullHeadshotDialog, {
    actorHeadshotId: props.actorHeadshotId,
  });
}

</script>

<template lang="pug">
.image-wrapper
  OButton.expand-icon(
    v-if="allowZoom && hasImage"
    icon-right="expand-alt"
    size="small"
    @click.stop="doZoom"
  )
  SignedImage(
    v-if="showImage"
    :width="width"
    :height="height"
    :urls="headshotUrlsFor(actorHeadshotId)"
    :image-id="actorHeadshotId"
    @fetch-urls="fetchHeadshotUrls"
  )
  StubImage(
    v-else
    :width="width"
    :height="height"
    :name="stubName"
  )
</template>

<style scoped>
.image-wrapper:hover .expand-icon {
    display: block;
}

.expand-icon {
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}
</style>
