import { useActorHeadshotStore } from '@/stores/actor_headshots';

export function headshotUrlsFor(headshotId) {
  return useActorHeadshotStore().retrieve(headshotId)?.urls;
}

export async function fetchHeadshotUrls(headshotId) {
  return await useActorHeadshotStore().retrieveUrls(headshotId).catch(() => null);
}

const EXTRACT_FILE_NAME = /([^/\n]+)\.\w+$/;

export function extractHeadshotName(file) {
  const m = file.name.match(EXTRACT_FILE_NAME);

  if (m !== null) {
    return m[1];
  }
}
