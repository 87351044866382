<script setup>
import SignedImage from '@/components/SignedImage.vue';
import { headshotUrlsFor, fetchHeadshotUrls } from '@/utilities/headshots';
import { computed, ref } from 'vue';
import { fetchImage } from '@/utilities/caching.js';
import { sleep } from '@/utilities/helpers';

const props = defineProps({
  actorHeadshotId: { type: String, required: true },
});

const emit = defineEmits(['close']);

const imageScale = ref(1);

const imageHeight = computed(() => `calc((100% - 10px) * ${imageScale.value}`);

const url = computed(() => {
  const urls = headshotUrlsFor(props.actorHeadshotId);
  return urls?.find(r => r.style === 'original');
});

function close() {
  emit('close', { action: 'close' });
}

async function printHeadshot() {
  // TODO second attempt shows nothing in FF
  window.print();
}

async function downloadHeadshot() {
  // blindly assumes the image is in the cache
  const response = await fetchImage(url.value.url);
  const blob = await response.blob();
  const tempUrl = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.download = url.value.fileName;
  a.href = tempUrl;
  document.body.appendChild(a);
  a.click();
  await sleep(3000);
  URL.revokeObjectURL(tempUrl);
}

defineOptions({
  dialogOptions: {
    fullScreen: true,
    canCancel: ['escape'],
  },
});
</script>

<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title Viewing actor headshot
    OField(label="Scale")
      OSlider(
        v-model="imageScale"
        :min="0.5"
        :max="4"
        :step="0.01"
        :tooltip="false"
      )
        OSliderTick(
          :value="1.0"
        ) Fit
    OField
      OButton(icon-right="print" @click="printHeadshot") Print
      OButton(icon-right="download" @click="downloadHeadshot") Download
    button.delete(aria-label="close" @click="close")
  section.modal-card-body
    SignedImage(
      :urls="headshotUrlsFor(actorHeadshotId)"
      :height="imageHeight"
      :image-id="actorHeadshotId"
      @fetch-urls="fetchHeadshotUrls"
    )
</template>

<style scoped lang="scss">
.modal-card-head {
  gap: 2rem;

  .modal-card-title {
    flex-grow: 0;
  }

  .field {
    flex-grow: 1;
  }
}

.modal-card-body {
  text-align: center;
}

@media print {
  :global(#app) {
    display: none;
  }
  .modal-card-head {
    display: none;
  }
  .modal-card-body {
    padding: 0;

    img {
      page-break-inside: avoid;
      max-height: 95vh;
      max-width: 100%;
      width: auto;
    }
  }
}
</style>
